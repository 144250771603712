import React, { useEffect } from "react";
import Page from "../Components/Page";
import { Box, Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

const Success = () => {
  const [countdown, setCountdown] = React.useState(15);

  const tick = () => {
    setCountdown(countdown - 1);
  };

  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  }, [countdown]);

  useEffect(() => {
    setTimeout(() => {
      window.location.assign("/");
    }, 15000);
  }, []);

  return (
    <Page>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: "100%",
          paddingX: "50px",
          paddingY: "50px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "40px ",
        }}
      >
        <Typography
          variant="h2"
          gutterBottom
          color={"primary"}
          fontWeight={"bold"}
        >
          Donation Recieved
        </Typography>
        <FontAwesomeIcon
          icon={faCircleCheck}
          fontSize={"10.8rem"}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            color: "#114A37",
          }}
        />
        <Typography variant="h6" color={"text.primary"}>
          Thank you for your donation!
        </Typography>
        <div style={{ height: "40px" }} />

        <Button
          variant="contained"
          color="primary"
          sx={{
            width: "40%",
            height: "60px",
            fontSize: "15px",
          }}
          onClick={() => (window.location.href = "/")}
        >
          Back to Home
        </Button>
        <Typography variant="body2" color={"text.primary"}>
          This page will redirect to the home page in {countdown} seconds.
        </Typography>
      </Box>
    </Page>
  );
};

export default Success;
