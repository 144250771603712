import { Box, Button, Typography } from "@mui/material";
import Page from "../Components/Page";

const Landing = () => {
  return (
    <Page>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
          }}
        >
          <Box
            sx={{
              height: "100%",
              paddingX: "80px",
              paddingY: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: "60px",
            }}
          >
            <>
              <Typography variant="h1" fontWeight={400} color={"text.primary"}>
                Start an{" "}
                <b
                  style={{
                    color: "#114A37",
                  }}
                >
                  endowment
                </b>{" "}
                for your masjid today, at{" "}
                <b
                  style={{
                    color: "#114A37",
                  }}
                >
                  no cost{" "}
                </b>
                to you.
              </Typography>
              <Button
                variant="outlined"
                fullWidth
                color="primary"
                sx={{
                  width: "60%",
                  height: "50px",
                  fontSize: "15px", // Set the font size in pixels (15px)
                }}
                onClick={() => {
                  window.location.assign("/start_endowment");
                }}
              >
                Start an Endowment
              </Button>
            </>

            {/* <Box
            sx={{
              width: "60%",
              height: "1px",
              backgroundColor: "primary.main",
              opacity: 0.5,
            }}
          />
          <>
            <Typography
              variant="h1"
              fontWeight={400}
              color={"text.primary"}
              sx={{ maxWidth: "60%" }}
            >
              USD{" "}
              <b
                style={{
                  color: "#114A37",
                }}
              >
                20M
              </b>{" "}
              raised for{" "}
              <b
                style={{
                  color: "#114A37",
                }}
              >
                300+
              </b>{" "}
              Masjids.
            </Typography>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              sx={{
                width: "40%",
                height: "50px",
                fontSize: "15px", // Set the font size in pixels (15px)
              }}
            >
              Donate to a Masjid Now
            </Button>
          </> */}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flex: 1,
          }}
        >
          <Box
            sx={{
              width: "100%",
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "92.8vh",
              flex: 1.5,
              opacity: 0.8,
              backgroundImage:
                "linear-gradient(to left, rgba(0,0,0,0), rgba(243,242,237,1)),linear-gradient(0deg, rgba(0,0,0,0), rgba(243,242,237,0.5)), url('/assets/bg.jpeg')",
              backgroundSize: "cover",
              backgroundPosition: "left",
            }}
          />
        </Box>
      </Box>
    </Page>
  );
};

export default Landing;
