import React, { useCallback, useEffect } from "react";
import "./App.css";
import { Container, CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./Utils/theme";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NotFound from "./Pages/NotFound";
import Landing from "./Pages/Landing";
import StartEndowment from "./Pages/StartEndowment";
import Impact from "./Pages/Impact";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import NavBar from "./Components/NavBar";
import Login from "./Pages/Login";
import AdminPage from "./Pages/Admin";
import Donate from "./Pages/Donate";
import Success from "./Pages/Success";
import Error from "./Pages/Error";

function App() {
  const [currentPage, setCurrentPage] = React.useState("home");
  const [loggedIn, setLoggedIn] = React.useState(false);

  //console.log("ENV", process.env.REACT_APP_API_URL);

  useEffect(() => {
    if (window.localStorage.getItem("user")) {
      setLoggedIn(true);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Container
          disableGutters
          maxWidth={false}
          sx={{
            margin: 0,
            backgroundColor: "background.default",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            backgroundImage: "url('/assets/bg_pattern.png')",
          }}
        >
          <NavBar
            theme={
              currentPage === "/home" || currentPage === "/" ? "light" : "dark"
            }
          />

          <Routes>
            <Route path="/*" element={<NotFound />} />
            <Route path="/" element={<Landing />} />
            <Route path="/home" element={<Landing />} />
            <Route path="/start_endowment" element={<StartEndowment />} />
            {/* <Route path="/impact" element={<Impact />} /> */}
            <Route path="/about_us" element={<AboutUs />} />
            <Route path="/our_team" element={<AboutUs />} />
            <Route path="/contact_us" element={<ContactUs />} />
            <Route path="/login" element={<Login />} />
            <Route path="/success" element={<Success />} />
            <Route path="/cancel" element={<Error />} />
            <Route path="/error" element={<Error />} />
            {loggedIn ? <Route path="/admin" element={<AdminPage />} /> : null}
          </Routes>
        </Container>
      </Router>
    </ThemeProvider>
  );
}

export default App;
