import React, { useEffect, useState } from "react";
import Page from "../Components/Page";
import { Box, Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

const Error = () => {
  const [countdown, setCountdown] = useState(15);

  const tick = () => {
    setCountdown(countdown - 1);
  };

  useEffect(() => {
    setTimeout(() => {
      window.location.assign("/");
    }, 15000);
  }, []);

  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  }, [countdown]);

  return (
    <Page>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: "100%",
          paddingX: "50px",
          paddingY: "50px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "40px ",
        }}
      >
        <Typography
          variant="h2"
          gutterBottom
          color={"error"}
          fontWeight={"bold"}
        >
          Payment Process Failed
        </Typography>
        <FontAwesomeIcon
          icon={faCircleXmark}
          fontSize={"10.8rem"}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            color: "#dc3545",
          }}
        />
        <Typography variant="h6" color={"error"}>
          We're sorry your payment process failed. Please contact us to resolve
          the issue.
        </Typography>
        <div style={{ height: "40px" }} />
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: "40%",
            height: "60px",
            fontSize: "15px",
          }}
          onClick={() => (window.location.href = "/contact_us")}
        >
          Contact Us
        </Button>
        <Typography variant="body2" color={"text.primary"}>
          This page will redirect to the home page in {countdown} seconds.
        </Typography>
      </Box>
    </Page>
  );
};

export default Error;
