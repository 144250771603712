import { ThemeOptions, createTheme } from "@mui/material/styles";

const theme: ThemeOptions = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#114A37",
    },
    secondary: {
      main: "#ffffff",
    },
    background: {
      default: "#F3F2ED",
      paper: "#ffffff",
    },
    text: {
      primary: "#1e1e1e",
      secondary: "#114A37",
      disabled: "#ffffff61",
    },
    error: {
      main: "#dc3545",
    },
    warning: {
      main: "#DC9935",
    },
    info: {
      main: "#35AADC",
    },
    success: {
      main: "#35DC82",
    },
    divider: "rgba(255,255,255,0.12)",
  },
  typography: {
    h1: {
      fontSize: "2.5rem",
      fontWeight: 600,
      fontFamily: "Montserrat",
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 600,
      fontFamily: "Montserrat",
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: 600,
      fontFamily: "Montserrat",
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 600,
      fontFamily: "Montserrat",
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 600,
      fontFamily: "Montserrat",
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 600,
      fontFamily: "Montserrat",
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 400,
      fontFamily: "Montserrat",
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      fontFamily: "Montserrat",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      fontFamily: "Montserrat",
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      fontFamily: "Montserrat",
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
      fontFamily: "Montserrat",
    },
    overline: {
      fontSize: "0.75rem",
      fontWeight: 400,
      textTransform: "uppercase",
      fontFamily: "Montserrat",
    },
  },
  shape: {
    borderRadius: 4,
  },
});

export { theme };
