import { Box, Button, Typography } from "@mui/material";
import Page from "../Components/Page";

const NotFound = () => {
  return (
    <Page>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: "100%",
          paddingX: "50px",
          paddingY: "50px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "40px ",
        }}
      >
        <div>
          <Typography
            variant="h1"
            gutterBottom
            fontSize={80}
            color={"primary"}
            fontWeight={"bold"}
            textAlign={"center"}
          >
            404
          </Typography>

          <Typography
            variant="body1"
            textAlign={"center"}
            color={"text.primary"}
          >
            We couldn't find this page anywhere.
          </Typography>
        </div>
        <div style={{ height: "40px" }} />

        <Button
          variant="contained"
          color="primary"
          sx={{
            width: "25%",
            height: "60px",
            fontSize: "15px",
          }}
          onClick={() => (window.location.href = "/")}
        >
          Back to Home
        </Button>
      </Box>
    </Page>
  );
};

export default NotFound;
