import React, { useState } from "react";
import Page from "../Components/Page";
import { Box, Button, TextField, Typography } from "@mui/material";
import TextBox from "../Components/TextBox";
import axios from "axios";
import { ErrorInterface } from "../Utils/ErrorInterface";
import Loading from "../Components/Loading";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<ErrorInterface>({
    isError: false,
    message: "",
    statusCode: 0,
  });

  const handleSubmit = async () => {
    setLoading(true);
    setError({
      isError: false,
      message: "",
      statusCode: 0,
    });

    let data = JSON.stringify({
      email: email,
      password: password,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/auth/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        // add the current time logged in to response.data

        //console.log(response.data);

        let res = response.data;

        window.localStorage.setItem("user", JSON.stringify(res));

        window.location.assign("/admin");

        setLoading(false);
      })
      .catch((error) => {
        //console.log(error);
        setError({
          isError: true,
          message: error.response.data.message,
          statusCode: error.response.status,
        });
        setLoading(false);
      });
  };

  return (
    <Page>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flex: 1.5,
        }}
      >
        <Box
          sx={{
            minHeight: "92vh",
            width: "50%",
            paddingX: "80px",
            paddingY: "50px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "60px",
          }}
        >
          <Typography variant="h2" color={"text.secondary"} fontWeight={700}>
            Admin Login
          </Typography>
          <TextBox
            label="Email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextBox
            label="Password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error.isError && (
            <Box
              style={{
                display: "flex",
                backgroundColor: "#ef5350",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                // gap: "20px",
              }}
              sx={{
                padding: 1,
                borderRadius: 1,
              }}
            >
              <Typography variant="subtitle2" color={"secondary.main"}>
                {error.message}
              </Typography>
            </Box>
          )}

          <Button
            variant="contained"
            disabled={loading}
            fullWidth
            color="primary"
            sx={{
              height: "50px",
            }}
            onClick={handleSubmit}
          >
            {loading ? <Loading /> : "Login"}
          </Button>
        </Box>
      </Box>
    </Page>
  );
};

export default Login;
