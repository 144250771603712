import axios from "axios";
import React, { useEffect, useState } from "react";
import Page from "../Components/Page";
import { Alert, Box, Button, Snackbar, Typography } from "@mui/material";
import TextBox from "../Components/TextBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ErrorInterface } from "../Utils/ErrorInterface";
import Loading from "../Components/Loading";

const AdminPage = () => {
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");

  const [address, setAddress] = useState("");
  const [addressLink, setAddressLink] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState("");

  const [successOpen, setSuccessOpen] = useState(false);
  const [error, setError] = useState<ErrorInterface>({
    isError: false,
    message: "",
    statusCode: 0,
  });

  const handleFileChange = (e: any) => {
    const files = e?.target.files;
    if (files && files.length > 0) {
      setImage(files[0]);
      setFileName(files[0].name);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const user = localStorage.getItem("user") as string;
    let token = "";
    if (!user) {
      window.location.assign("/login");
      return;
    } else {
      token = JSON.parse(user).token;
    }

    let data = new FormData();
    data.append("name", name);
    data.append("address", address);
    data.append("addressLink", addressLink);
    data.append("description", description);
    if (image) {
      data.append("file", image);
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/masjid/createMasjid`,
      headers: {
        Authorization: token,
      },
      data: data,
    };
    setError({
      isError: false,
      message: "",
      statusCode: 0,
    });
    await axios
      .request(config)
      .then((response) => {
        //console.log(response.data);
        setSuccessOpen(true);
        setName("");
        setAddress("");
        setAddressLink("");
        setDescription("");
        setImage(null);
        setFileName("");

        setLoading(false);
      })
      .catch((error) => {
        setError({
          isError: true,
          message: error.response.data,
          statusCode: error.response.status,
        });

        if (error.response.status === 401) {
          window.location.assign("/login");
          localStorage.removeItem("user");
        }
        if (error.response.status === 404) {
          window.location.assign("/login");
          localStorage.removeItem("user");
        }
        setLoading(false);
        //console.log(error);
      });
  };

  return (
    <Page>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            minHeight: "92vh",
            width: "100%",
            paddingX: "80px",
            paddingY: "50px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "space-between",
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "60px",
              flex: 1,
            }}
          >
            <Typography variant="h2" color={"text.secondary"} fontWeight={700}>
              Create Masjid
            </Typography>
            <TextBox
              label="Name"
              name="name"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextBox
              label="Address"
              name="address"
              placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <TextBox
              label="Address Link"
              name="addressLink"
              placeholder="Address Link"
              value={addressLink}
              onChange={(e) => setAddressLink(e.target.value)}
            />
            <TextBox
              label="Description"
              name="description"
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            <Button
              variant="contained"
              disabled={loading}
              fullWidth
              color="primary"
              sx={{
                height: "50px",
              }}
              onClick={handleSubmit}
            >
              {loading ? <Loading /> : "Create Masjid"}
            </Button>
            {error.isError && (
              <Box
                style={{
                  display: "flex",
                  backgroundColor: "#ef5350",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  // gap: "20px",
                }}
                sx={{
                  padding: 1,
                  borderRadius: 1,
                }}
              >
                <Typography variant="subtitle2" color={"secondary.main"}>
                  {error.message}
                </Typography>
              </Box>
            )}
          </Box>
          <Typography variant="h2" color={"transparent"} fontWeight={700}>
            Create Masjid
          </Typography>
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "60px",
              flex: 1,
            }}
          >
            <Box
              sx={{
                backgroundColor: "background.paper",
                color: "grey",
                borderRadius: 2,
                border: "2px dashed grey",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: `100%`,
                height: `100%`,
              }}
            >
              <label
                style={{
                  padding: 20,
                  cursor: "pointer",
                }}
                htmlFor="fileInput"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    fontSize={"1.8rem"}
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      color: "text.primary",
                    }}
                  />
                  <Typography variant="subtitle2" fontWeight={400}>
                    <a>Browse your computer</a> and select the image you want to
                    upload.
                  </Typography>
                </div>
              </label>
              <input
                id="fileInput"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </Box>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 5,
                width: "100%",
              }}
            >
              <Typography variant="subtitle2" fontWeight={400} color={"grey"}>
                {fileName === "" ? "No file selected" : fileName}
              </Typography>
            </div>
          </Box>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={successOpen}
        onClose={() => {
          setSuccessOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setSuccessOpen(false);
          }}
          severity="success"
          variant="standard"
          sx={{ width: "100%" }}
        >
          Masjid Created Successfully
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default AdminPage;
