import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";

// const navItems = ["Start Endowment", "About Us", "Our Team", "Contact Us"];
const navItems = [
  {
    label: "Start an Endowment",
    link: "/start_endowment",
  },
  {
    label: "About Us",
    link: "/about_us",
  },
  {
    label: "Our Team",
    link: "/our_team",
  },
  {
    label: "Contact Us",
    link: "/contact_us",
  },
];

interface NavBarProps {
  theme?: "light" | "dark";
}

const NavBar = ({ theme = "dark" }: NavBarProps) => {
  const [currentPage, setCurrentPage] = useState("home");
  const [currentHash, setCurrentHash] = useState("");

  useEffect(() => {
    // //console.log("this is running");
    const path = window.location.pathname;

    setCurrentPage(path);
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <AppBar
        component={"div"}
        elevation={0}
        sx={{
          backgroundColor:
            theme === "light" ? "background.default" : "primary.main",
          backgroundImage:
            theme === "light" ? "url('/assets/bg_pattern.png')" : "",
          backgroundSize: "cover",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => {
              window.location.assign("/home");
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography
              variant="h6"
              color={theme === "light" ? "text.secondary" : "white"}
            >
              Islamic Endowment Foundation
            </Typography>
          </div>
          <Box>
            {navItems.map((item) => (
              <Button
                onClick={() => {
                  window.location.assign(item.link);
                }}
                key={item.label}
                sx={{
                  color: theme === "light" ? "text.primary" : "white",
                  marginX: 2,
                }}
              >
                <Typography
                  variant="caption"
                  fontWeight={currentPage === item.link ? 600 : 400}
                >
                  {item.label}
                </Typography>
              </Button>
            ))}
          </Box>
          {/* <Button
            variant="contained"
            onClick={() => {
              window.location.assign("/impact");
            }}
            sx={{
              backgroundColor: theme === "light" ? "primary.main" : "white",
              color: theme === "light" ? "white" : "primary.main",
              "&:hover": {
                backgroundColor:
                  theme === "light" ? "primary.dark" : "lightgrey",
              },
            }}
          >
            <Typography variant="caption" fontWeight={600}>
              Donate to a Masjid Now
            </Typography>
          </Button> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NavBar;
