import { Box, ListItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Page from "../Components/Page";

const AboutUs = () => {
  const [selectedHeading, setSelectedHeading] = useState("");

  const headings = [
    "What is an Endowment",
    "History",
    "Our Team",
    // "Impact",
    // "FAQs",
  ];

  const handleHeadingClick = (heading: string) => {
    setSelectedHeading(heading);
  };

  useEffect(() => {
    if (window.location.pathname === "/our_team") {
      setSelectedHeading("Our Team");
      window.location.hash = "Our Team";
    }
  }, []);

  return (
    <Page>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: "100%",
          paddingX: "80px",
          paddingY: "50px",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: "260px",
        }}
      >
        <Box
          sx={{
            width: "60%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "20px",
          }}
        >
          <Typography
            variant="h2"
            gutterBottom
            color={"primary"}
            fontWeight={"bold"}
          >
            About Us
          </Typography>
          <Box sx={{ height: "20px" }} />
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
            id="What is an Endowment"
          >
            <Typography id="Introduction" variant="h4" gutterBottom>
              What is an Endowment?
            </Typography>
            <Typography variant="body1" gutterBottom>
              An endowment is a pot of donated money that is invested to create
              a reliable stream of annual income for a charitable organization.
              <br />
              <br />
              Endowments are used by colleges, schools, churches, synagogues,
              and nonprofits because they ensure stable, annual returns that can
              be used to fund operational expenses.
            </Typography>
          </section>

          <section
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
            id="History"
          >
            <Typography id="MainSection" variant="h4" gutterBottom>
              History
            </Typography>
            <Typography variant="body1" gutterBottom>
              We know that many Islamic organizations in the United States do
              not have endowments and are financially unsustainable. Started in
              February 2024, The Islamic Endowment Foundation establishes,
              grows, and manages individualized endowment funds for Masajid and
              other Islamic organizations across the United States. With our
              nonprofit status and endowment and investment knowledge, we strive
              to foster financial sustainability for all Islamic organizations
              in the United States through sharia-compliant endowments, at no
              cost to your Islamic organization.
            </Typography>
          </section>
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              marginBottom: "20px",
            }}
            id="Our Team"
          >
            <Typography id="Conclusion" variant="h4" gutterBottom>
              Our Team
            </Typography>
            <Typography variant="body1" gutterBottom>
              The founder of The Islamic Endowment Foundation, Abduelwahab
              Hussein, earned a B.S. in Mathematics from William & Mary. He is
              passionate about uplifting the financial condition of the Ummah in
              the United States and is active in the New York City and Northern
              Virginia muslim communities. His goal is to ensure Islamic
              organizations are financially sustainable for generations to come.
              <br />
              <br />
              Our Chief Finance Advisor, Motasem Benothman, earned a B.S. in
              Electrical Engineering and MBA from UCLA. He is the founder of
              Portola Springs, an investment fund, and is also the Chairman of
              New Horizon Irvine Foundation, a trust that manages a multimillion dollar
              endowment for an Islamic school. He also holds extensive knowledge in
              finance and endowment investment.
              <br />
              <br />
              Our Chief Legal Advisor & Compliance Officer, Jener Sakiri, earned
              a B.A. in Political Science and J.D. from UCLA. He previously
              served as the Head of Legal for Niftify, and is now an associate
              attorney at Zuber Lawler, with a focus on finance and business
              law.
              <br />
              <br />
              Our Community Engagement Advisor, Yousof Omeish, earned a B.A. in
              History from Yale University. He worked as a deputy campaign
              manager for Muad Hrezi for U.S. Congress and is curently a special
              assistant to Senator Michael Bennet. He is passionate about muslim
              engagement in politics and serves as a consultant on matters of
              community and social engagment with Masajid and other Islamic
              organizations.
            </Typography>
          </section>
          {/* <section
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
            id="Impact"
          >
            <Typography id="Conclusion" variant="h4" gutterBottom>
              Impact
            </Typography>
            <Typography variant="body1" gutterBottom>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
              mollis tempor facilisis. Suspendisse vitae arcu mauris. Donec et
              massa sed libero pretium tincidunt sit amet eu velit. Cras eget
              feugiat diam, laoreet semper eros. Vestibulum gravida nisi eu
              lacus pharetra, sit amet eleifend justo sagittis. Nulla nec nulla
              non lorem lacinia ultricies tempor a purus. Proin interdum
              scelerisque mauris, vel tempor nunc. Nullam viverra odio ut orci
              hendrerit, nec tincidunt neque iaculis. Morbi consequat vel lectus
              non elementum. Sed malesuada neque in blandit ultricies. Maecenas
              semper turpis eu tempor tristique. Ut vel felis non lorem dapibus
              tristique. Donec eget turpis metus. Sed in risus non justo
              ultricies iaculis vel id risus. Phasellus quis pellentesque nisl,
              vel pellentesque augue. Pellentesque sollicitudin lorem est, a
              aliquam arcu suscipit eu. Nam a eros magna. Etiam a nulla a mi
              faucibus consectetur. Phasellus rutrum nisi vitae venenatis
              volutpat. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Etiam commodo nunc nunc, in faucibus turpis varius ut. Sed non
              dolor fermentum, bibendum lacus ut, fermentum justo. Phasellus
              lectus arcu, blandit eu volutpat vel, sodales vitae augue. Orci
              varius natoque penatibus et magnis dis parturient montes, nascetur
              ridiculus mus. Etiam feugiat condimentum diam, non volutpat tellus
              feugiat vel. Duis ultrices augue purus, ut luctus leo porttitor
              id. Cras porta mattis sagittis. Nam quis porttitor ex. Praesent
              leo tortor, rutrum id mollis vel, facilisis id justo. In mattis
              massa eu risus fermentum sagittis. Sed molestie est lorem, et
              condimentum enim dignissim nec. Ut rhoncus ornare erat. Morbi
              scelerisque augue nec porta fermentum. Phasellus a tristique quam.
              In a semper quam. Donec rutrum commodo venenatis. Nulla id justo
              sit amet elit ultrices sodales. Vivamus et pulvinar velit. Vivamus
              faucibus, neque et commodo convallis, lacus lacus hendrerit nisi,
              et porttitor augue enim in odio. Vivamus semper ante massa, in
              sollicitudin mi maximus vitae. Donec nec purus eu turpis tempus
              scelerisque. Vestibulum ante ipsum primis in faucibus orci luctus
              et ultrices posuere cubilia curae; In eu augue fringilla, mollis
              sem nec, commodo augue. In aliquet metus quis eros tempor dictum.
              Sed condimentum egestas est, et suscipit dui commodo id. Morbi
              malesuada vehicula sem vel pharetra. Cras convallis turpis at ante
              interdum sodales. Praesent eget ante ex. Curabitur nec eros
              laoreet, consequat tellus et, gravida metus. Ut sagittis cursus
              nibh eu aliquet. Aliquam nulla enim, bibendum quis turpis at,
              pharetra lacinia lacus. In posuere elit non sagittis malesuada.
              Vestibulum tellus massa, eleifend eu porta id, efficitur eu dui.
            </Typography>
          </section>
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
            id="FAQs"
          >
            <Typography id="Conclusion" variant="h4" gutterBottom>
              FAQs
            </Typography>
            <Typography variant="body1" gutterBottom>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
              mollis tempor facilisis. Suspendisse vitae arcu mauris. Donec et
              massa sed libero pretium tincidunt sit amet eu velit. Cras eget
              feugiat diam, laoreet semper eros. Vestibulum gravida nisi eu
              lacus pharetra, sit amet eleifend justo sagittis. Nulla nec nulla
              non lorem lacinia ultricies tempor a purus. Proin interdum
              scelerisque mauris, vel tempor nunc. Nullam viverra odio ut orci
              hendrerit, nec tincidunt neque iaculis. Morbi consequat vel lectus
              non elementum. Sed malesuada neque in blandit ultricies. Maecenas
              semper turpis eu tempor tristique. Ut vel felis non lorem dapibus
              tristique. Donec eget turpis metus. Sed in risus non justo
              ultricies iaculis vel id risus. Phasellus quis pellentesque nisl,
              vel pellentesque augue. Pellentesque sollicitudin lorem est, a
              aliquam arcu suscipit eu. Nam a eros magna. Etiam a nulla a mi
              faucibus consectetur. Phasellus rutrum nisi vitae venenatis
              volutpat. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Etiam commodo nunc nunc, in faucibus turpis varius ut. Sed non
              dolor fermentum, bibendum lacus ut, fermentum justo. Phasellus
              lectus arcu, blandit eu volutpat vel, sodales vitae augue. Orci
              varius natoque penatibus et magnis dis parturient montes, nascetur
              ridiculus mus. Etiam feugiat condimentum diam, non volutpat tellus
              feugiat vel. Duis ultrices augue purus, ut luctus leo porttitor
              id. Cras porta mattis sagittis. Nam quis porttitor ex. Praesent
              leo tortor, rutrum id mollis vel, facilisis id justo. In mattis
              massa eu risus fermentum sagittis. Sed molestie est lorem, et
              condimentum enim dignissim nec. Ut rhoncus ornare erat. Morbi
              scelerisque augue nec porta fermentum. Phasellus a tristique quam.
              In a semper quam. Donec rutrum commodo venenatis. Nulla id justo
              sit amet elit ultrices sodales. Vivamus et pulvinar velit. Vivamus
              faucibus, neque et commodo convallis, lacus lacus hendrerit nisi,
              et porttitor augue enim in odio. Vivamus semper ante massa, in
              sollicitudin mi maximus vitae. Donec nec purus eu turpis tempus
              scelerisque. Vestibulum ante ipsum primis in faucibus orci luctus
              et ultrices posuere cubilia curae; In eu augue fringilla, mollis
              sem nec, commodo augue. In aliquet metus quis eros tempor dictum.
              Sed condimentum egestas est, et suscipit dui commodo id. Morbi
              malesuada vehicula sem vel pharetra. Cras convallis turpis at ante
              interdum sodales. Praesent eget ante ex. Curabitur nec eros
              laoreet, consequat tellus et, gravida metus. Ut sagittis cursus
              nibh eu aliquet. Aliquam nulla enim, bibendum quis turpis at,
              pharetra lacinia lacus. In posuere elit non sagittis malesuada.
              Vestibulum tellus massa, eleifend eu porta id, efficitur eu dui.
            </Typography>
          </section> */}
        </Box>
        <Box
          sx={{
            position: "fixed",
            right: "5%",
            top: "15%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "10px",
          }}
        >
          {headings.map((heading) => (
            <ListItem
              className="nav-link"
              component="a"
              href={`#${heading}`}
              key={heading}
              onClick={() => handleHeadingClick(heading)}
              style={{
                textDecoration: "none",
              }}
              sx={{
                color: "#000",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              <Typography
                variant="h6"
                fontWeight={heading === selectedHeading ? "bold" : "normal"}
              >
                {heading}
              </Typography>
            </ListItem>
          ))}
        </Box>
      </Box>
    </Page>
  );
};

export default AboutUs;
