import { Box, Button, Typography } from "@mui/material";
import React from "react";
import Page from "../Components/Page";

const StartEndowment = () => {
  return (
    <Page>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
          }}
        >
          <Box
            sx={{
              height: "100%",
              paddingX: "80px",
              paddingY: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: "60px",
            }}
          >
            <Typography variant="h2" color={"text.secondary"} fontWeight={700}>
              Start an Endowment for your Islamic Organization
            </Typography>
            <Typography
              variant="body1"
              fontSize={20}
              color={"text.secondary"}
              fontWeight={400}
              style={{ maxWidth: "80%" }}
            >
              An endowment fund that generates yearly income is essential in
              ensuring that Islamic organizations continue to thrive financially
              regardless of future conditions and emergencies.
            </Typography>
            <Typography
              variant="body1"
              fontSize={18}
              color={"text.secondary"}
              fontWeight={400}
              fontStyle={"italic"}
              style={{ maxWidth: "80%" }}
            >
              Please contact our investment team below to start a
              sharia-compliant endowment fund today with your Islamic
              organization's specific investment goals in mind:
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{
                  width: "40%",
                  height: "60px",
                  fontSize: "15px",
                }}
                onClick={() => (window.location.href = "/contact_us")}
              >
                Contact Us
              </Button>
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  width: "40%",
                  height: "50px",
                  fontSize: "15px",
                }}
                onClick={() => (window.location.href = "/our_team#Our%20Team")}
              >
                Our Investment Team
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flex: 1,
          }}
        >
          <Box
            sx={{
              width: "100%",
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "92vh",
              flex: 1,
              backgroundImage: "url('/assets/golden_pattern.png')",
              backgroundSize: "cover",
              // backgroundRepeat: "no-repeat",
              backgroundPosition: "left",
            }}
          />
        </Box>
      </Box>
    </Page>
  );
};

export default StartEndowment;
